$( document ).on('turbo:load', function() {
  const tabPages = [...document.querySelectorAll("[data-tab-id]")];
  const tabButtons = tabPages.map(e =>
    document.querySelector(`[data-tab-target="${e.dataset.tabId}"]`)
  );

  function selectTab(id) {
    tabButtons.forEach(
      el =>
        el.dataset.tabTarget === id
          ? el.classList.add("border-t", "border-l", "border-r", "bg-white")
          : el.classList.remove("border-t", "border-l", "border-r", "bg-white")
    );
    tabPages.forEach(
      el =>
        el.dataset.tabId === id
          ? (el.style.display = "block")
          : (el.style.display = "none")
    );
  }

  function toggler(e) {
    selectTab(e.target.dataset.tabTarget);
  }

  if (tabPages.length > 0) {
    tabButtons.forEach(el => {
      el.addEventListener("click", toggler, false);
    });
    selectTab(tabPages[0].dataset.tabId);
  }
});
