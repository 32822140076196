import "@hotwired/turbo-rails"

import Rails from "@rails/ujs"

import "./src/jquery.js"
import "../../node_modules/jquery-ui/dist/jquery-ui"

import initAutocomplete from "./src/autocomplete.js";
import "./src/dropdown.js";
import "./src/stickySidebar.js";
import "./src/toggleLanguages.js";
import "./src/liveToc.js";
import "./src/choices.js";
import "./src/tabs.js";

$( document ).on('turbo:load', function() {
  initAutocomplete();
});

Rails.start();
