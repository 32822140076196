$( document ).on('turbo:load', function() {
  const dropdownMenus = document.querySelectorAll("[data-dropdown]");
  Array.prototype.forEach.call(dropdownMenus, el => {
    const dropdownName = el.getAttribute("data-dropdown");
    const dropdownMenu = document.getElementById(dropdownName);
    if (dropdownMenu) {
      el.addEventListener("click", e => dropdownMenu.classList.toggle("hidden"));
    }
  });
});
