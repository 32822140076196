import { template, debounce } from "./utils";

export default function initAutocomplete() {
  var searchField = document.getElementById("search-field");
  if (!(searchField && searchField.dataset.autocomplete)) return;

  var tChosen = template(document.getElementById("tmpl-chosen").innerHTML);
  var tOption = template(document.getElementById("tmpl-option").innerHTML);
  var selectedNode = document.getElementById("selected-options");
  var optionsNode = document.getElementById("autocomplete-options");
  var spinner = document.getElementById("spinner");
  var lastResult = null;

  function showSpinner() {
    spinner.classList.remove("hidden");
  }

  function showOptions(profiles) {
    spinner.classList.add("hidden");
    lastResult = profiles;
    if (lastResult.length > 0) {
      var fragment = document.createElement("div");
      profiles.forEach(function(profile) {
        var node = document.createElement("div");
        node.innerHTML = tOption(profile);
        fragment.appendChild(node);
      });
      optionsNode.innerHTML = fragment.innerHTML;
    } else {
      optionsNode.innerHTML = "Nothing found.";
    }
  }

  function groupProfilesByVersion(profiles) {
    var byVersionId = {};
    for (var i = 0, j = profiles.length; i < j; i++) {
      var profile = profiles[i];
      var profileId = profile.profile_version_id;
      if (profileId in byVersionId) {
        byVersionId[profileId].languages.push(profile.language);
      } else {
        profile.languages = [profile.language];
        byVersionId[profileId] = profile;
      }
    }
    var output = [];
    for (var profileVersionId in byVersionId) {
      if (byVersionId.hasOwnProperty(profileVersionId)) {
        output.push(byVersionId[profileVersionId]);
      }
    }
    return output;
  }

  optionsNode.addEventListener("click", function(e) {
    if (e.target.dataset.addid) {
      e.preventDefault();
      var id = e.target.dataset.addid;
      var profile = lastResult.find(function(profile) {
        return profile.id == id;
      });
      var node = document.createElement("div");
      node.innerHTML = tChosen(profile);
      selectedNode.appendChild(node);
      optionsNode.innerHTML = "";
      searchField.value = "";
    }
  });

  selectedNode.addEventListener("click", function(e) {
    if (e.target.dataset.remove) {
      e.preventDefault();
      e.target.parentNode.parentNode.parentNode.removeChild(
        e.target.parentNode.parentNode
      );
    }
  });

  searchField.addEventListener(
    "input",
    debounce(function(e) {
      if (e.target.value !== "") {
        var value = e.target.value;
        showSpinner();
        fetch(
          searchField.dataset.autocompleteUrl +
            "?filter[q]=" +
            encodeURIComponent(value)
        )
          .then(function(response) {
            return response.json();
          })
          .then(function(json) {
            showOptions(groupProfilesByVersion(json));
          });
      } else {
        showOptions([]);
      }
    }, 500)
  );
}
