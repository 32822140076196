function throttle(callback, time) {
  let wait = false;
  return function() {
    if (wait) return;
    callback();
    wait = true;
    setTimeout(function() {
      wait = false;
    }, time);
  };
}

const toc = document.getElementById("live-toc");
if (toc) {
  const links = toc.querySelectorAll("a");
  const headings = [];
  for (let i = 0, j = links.length; i < j; i++) {
    headings.push(document.querySelector(links[i].getAttribute("href")));
  }
  window.addEventListener(
    "scroll",
    throttle(e => {
      const heading = headings.find(h => h.getBoundingClientRect().bottom > 0);
      if (heading) {
        window.requestAnimationFrame(function() {
          for (let i = 0, j = links.length; i < j; i++) {
            if (links[i].getAttribute("href") === "#" + heading.id) {
              links[i].classList.add("text-grey-darkest", "hover:text-black");
              links[i].classList.remove(
                "text-grey-dark",
                "hover:text-grey-darker"
              );
            } else {
              links[i].classList.add(
                "text-grey-dark",
                "hover:text-grey-darker"
              );
              links[i].classList.remove(
                "text-grey-darkest",
                "hover:text-black"
              );
            }
          }
        });
      }
    }, 100)
  );
}
