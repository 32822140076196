$( document ).on('turbo:load', function() {
  const formGroupFilterLanguages = document.querySelector(
    ".form-group.filter_languages"
  );
  const nrLanguages = 6;
  if (formGroupFilterLanguages) {
    const options = formGroupFilterLanguages.querySelectorAll(".form-check");
    const nrMoreLanguages = options.length - nrLanguages;
    if (nrMoreLanguages > 0) {
      const moreLanguages = Array.prototype.slice.call(options, nrLanguages);
      moreLanguages.forEach(el => el.classList.toggle("hidden"));
      const toggler = document.createElement("div");
      toggler.appendChild(
        document.createTextNode(`Show more (${nrMoreLanguages})`)
      );
      toggler.className =
        "font-bold text-xs text-blue-light cursor-pointer hover:text-blue hover:underline";
      toggler.addEventListener(
        "click",
        e => {
          moreLanguages.forEach(el => el.classList.toggle("hidden"));
          toggler.classList.add("hidden");
        },
        false
      );
      formGroupFilterLanguages.appendChild(toggler);
    }
  }
});
