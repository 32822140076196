import Choices from "choices.js";

$( document ).on('ready turbo:load', function() {
  for (let id of [
    "#profile_version_translations_attributes_0_country",
    "#profile_version_translations_attributes_0_profession",
    "#profile_version_translations_attributes_0_region",
    "#profile_version_translations_attributes_0_languages",
    "#profile_version_translations_attributes_0_experience_levels",
    "#profile_version_translations_attributes_0_theme",
    "#profile_version_translations_attributes_0_affiliations",
    "#profile_version_translations_attributes_0_area",
    "#profile_version_translations_attributes_1_country",
    "#profile_version_translations_attributes_1_profession",
    "#profile_version_translations_attributes_1_region",
    "#profile_version_translations_attributes_1_languages",
    "#profile_version_translations_attributes_1_experience_levels",
    "#profile_version_translations_attributes_1_theme",
    "#profile_version_translations_attributes_1_affiliations",
    "#profile_version_translations_attributes_1_area",
    "#profile_country",
    "#profile_profession",
    "#profile_region",
    "#profile_languages",
    "#profile_experience_levels",
    "#profile_theme",
    "#profile_affiliations",
    "#profile_area"
  ]) {
    if (document.querySelector(id)) {
      new Choices(id, { removeItemButton: true });
    }
  }
});
